import React, { useEffect, useState } from 'react';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../action/authAction';
import { FaSpinner } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');

    const [password, setPassword] = useState('');
    const [loader, setLoader] = useState(false)
    const auth = useSelector(state => state.auth);
    useEffect(() => {

        if (auth.isAuthenticated) {
            navigate('/dashboard')
            // window.location.reload();

        }
    }, [auth])
    const handleClick = async (e) => {
        e.preventDefault();
        setLoader(true)
        try {
            let data = {
                email, password
            }
            dispatch(loginUser(data, navigate, setLoader))

        } catch (error) {
            console.error("Login error:", error);
        }
    }

    return (
        <>
            <div className='container-fluid p-0 main-container'>
                <Helmet>
                    <title> Reader-Invoice | Login</title>
                </Helmet>
                <div className='row login px-3 py-4 rounded-3'>
                    <div className='form-login'>
                        <h2 className='heading'>Login to continue</h2>
                        <form className='form' onSubmit={handleClick}>
                            <div>
                                <label htmlFor="username" className="form-label">Email:</label>
                                <input type="email" name="email" id="username" className="form-control" placeholder='Enter the email' required onChange={(e) => setEmail(e.target.value)} value={email} />
                            </div>
                            <div>
                                <label htmlFor="password" className="form-label">Password:</label>
                                <input type="password" name="password" id="password" className="form-control" placeholder='Enter the password' required onChange={(e) => setPassword(e.target.value)} value={password} />
                            </div>
                            <div className='btn-login'>
                                <button type="submit" className='w-100 border-0 px-5 py-2 rounded-2'>Login {loader ? <FaSpinner className="spin" /> : " "}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
