import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { deleteFolder, updateFolderName } from "../../action/authAction";
import { useDispatch } from "react-redux";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";

const FolderRow = ({
  item,
  setSelectedFolderId,
  handelFetchTable,
  fetchFolder,
  handelFetchTableById,
}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    id: "",
    name: "",
  });
  const [editFolderId, setEditFolderId] = useState(null);
  const [editFolderName, setEditFolderName] = useState("");
  const [editFolderDate, setEditFolderDate] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleNameChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  const startEditing = (folderId, initialName, date) => {
    setShow(true);
    setEditFolderId(folderId);

    setEditFolderName(initialName);
    setEditFolderDate(date);
  };

  const cancelEditing = () => {
    setShow(false);
    setEditFolderId(null);
    setEditFolderName("");
    setEditFolderDate("");
  };

  const saveFolderName = (e) => {
    e.preventDefault();
    let data = {
      id: state.id,
      name: state.name,
    };

    dispatch(updateFolderName(data, fetchFolder, handelFetchTableById));
    setShow(false);
    setEditFolderId(null);
  };
  // const handleDeleteFolder = (folderId) => {
  //     Swal.fire({
  //         icon: 'error',
  //         title: 'Oops...',
  //         text: 'You cannot delete last slide!',
  //         confirmButtonColor: "#d03e3e"
  //     })
  //     if (confirmed) {
  //         dispatch(deleteFolder(folderId, fetchFolder, () => {
  //             setSelectedFolderId(null);
  //             handelFetchTable();
  //         }));
  //     }
  // };

  const handleDeleteFolder = async (folderId) => {
    const result = await Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You will not be able to recover this folder!",
      showCancelButton: true,
      confirmButtonColor: "#d03e3e",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      dispatch(
        deleteFolder(folderId, fetchFolder, () => {
          setSelectedFolderId(null);
          handelFetchTable();
        })
      );
    }
  };

  const handleFolderSelect = (folderId) => {
    setEditFolderId(null);
    setSelectedFolderId(folderId);
  };
  useEffect(() => {
    setState({
      ...state,
      id: item.id,
      name: item.name,
    });
  }, [item.id]);
  return (
    <div key={item.id} className="form-check checkFolder rounded-1">
      <>
        <div className="w-100" onClick={() => handleFolderSelect(item.id)}>
          {/* <label className="form-check-label w-100"> */}
          <span className="short-txt">{item.name} </span>
          {/* <span> {item.created.split(" ")}</span> */}
          {/* </label> */}
        </div>
        <div className="editDeleteBtn d-flex align-items-center gap-1">
          <button
            onClick={() => startEditing(item.id, item.name)}
            className="edit"
          >
            <FaEdit />
          </button>
          <button
            onClick={() => handleDeleteFolder(item.id)}
            className="deleteBtn"
          >
            <MdDelete />
          </button>
        </div>
      </>

      {editFolderId === item.id ? (
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title className="modal-heading">
              Edit Folder Name
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={(e) => saveFolderName(e)}>
              <div className="editName">Name</div>
              <input
                className="rounded-2 editFolderNameInput"
                type="text"
                name="name"
                onChange={(e) => handleNameChange(e)}
                defaultValue={item?.name}
                maxLength={30}
                required
              />
              <div className="handle d-flex justify-content-end">
                <Button type="submit" className="btnhandle updateBtn">
                  Update
                </Button>
                <Button
                  type="button"
                  onClick={cancelEditing}
                  className="btnhandle cancelBtn"
                >
                  Cancel
                </Button>
              </div>
            </form>
            {/* <div>
                Date
            </div>
            <input type='date' name='date' value={item.created.split(" ")} required /> */}
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
};

export default FolderRow;
