import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './components/Login/Login';
import Sample from './components/Sample';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from './store';
import NavbarComp from './components/Navbar/Navbar';
import Alert from './components/Common/Alert';
import { removeAlert } from './action/alertAction';
import './App.css'
import PrivateRoute from './components/Common/PrivateRoute';

function App() {
  const dispatch = useDispatch();
  const alert = useSelector(state => state.alert)

  useEffect(() => {
    if (alert !== undefined) {
      if (alert.message !== "") {
        setTimeout(() => {
          dispatch(removeAlert())
        }, 4000);
      }
    }
  }, [alert, dispatch])

  return (
    <Provider store={store}>
      <Alert />
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<PrivateRoute> <NavbarComp /></PrivateRoute>} />
          <Route path="/dashboard" element={<PrivateRoute> <NavbarComp /></PrivateRoute>} />
        </Routes>
      </BrowserRouter>
    </Provider>

  );
}

export default App;
