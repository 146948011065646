import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { CiCircleList } from "react-icons/ci";
import { RxUpload } from "react-icons/rx";
import "./style.css";
import {
  fetchDataById,
  onFetchFolder,
  onUploadDocs,
  onUploadMedia,
} from "../../action/authAction";
import { useDispatch } from "react-redux";
import { themeColor } from "../../Global/Global";
import { AiOutlineCloudUpload } from "react-icons/ai";
import Swal from "sweetalert2";
import { FaSpinner } from "react-icons/fa";

let mediaType;

const UploadCSV = ({
  setFolder,
  folder,
  selectedFolderId,
  setFolderData,
  handelFetchTableById,
  handelFetchTable,
}) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [percent, setPercent] = useState(0);
  const [state, setState] = useState(null);
  const [optionId, setOptionId] = useState("select folder");
  const [fileName, setFileName] = useState("");
  const dispatch = useDispatch();
  const [loader, setLoader] = useState({
    upload: false,
  });
  const handleClose = () => {
    setShow(false);
    setOptionId("select folder");
    setFileName("");
  };

  const [fileData, setFileData] = useState({
    name: "",
  });
  const fetchFolder = () => {
    dispatch(onFetchFolder(setFolder, setLoader));
  };
  useEffect(() => {
    fetchFolder();
  }, []);
  const allowItems1 = [
    "text/csv",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
    ".zip",
  ];
  const [activeUpload, setActiveUpload] = useState(false);
  const onCompeleteUpload = (obj) => {
    setFileData({
      ...fileData,
      ...obj,
    });

    handleClose();
    setLoader({
      ...loader,
      upload: false,
    });
  };

  const onChange = (e) => {
    const file = e.target.files[0];
    setState(file);
    if (file) {
      setFileName(file.name);
    }
  };
  const uploadFile = (e) => {
    if (state) {
      const selectedFile = state;
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("fId", optionId);
      if (allowItems1.includes(selectedFile.type.toLowerCase())) {
        if (selectedFile.size < 300000000) {
          formData.append("fId", optionId);

          if (selectedFile.type.toLowerCase() === "text/csv") {
            mediaType = "csv";
          }

          setFileData({
            ...fileData,
            name: selectedFile.name,
          });

          setActiveUpload(true);
          setLoader({
            upload: true,
          });
          dispatch(
            onUploadDocs(
              "upload-file",
              formData,
              onCompeleteUpload,
              setPercent,
              handelFetchTableById,
              handelFetchTable,
              loader,
              setLoader
            )
          );
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Max allowed size is 300MB!",
            confirmButtonColor: themeColor,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "You have selected an invalid file type!",
          confirmButtonColor: themeColor,
        });
      }
    }
    setState(null);
  };
  const handleChange = (id) => {
    setOptionId(id);
  };
  return (
    <>
      <button
        onClick={handleShow}
        className="upload rounded-2 text-light py-2 px-4"
      >
        <AiOutlineCloudUpload className="iconlist text-light fw-400" /> Upload
        CSV
      </button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <p className="m-0 modal-heading">Upload CSV</p>
        </Modal.Header>
        <Modal.Body
          style={{ display: "flex", flexDirection: "column", gap: "20px" }}
        >
          <div>
            <p className="m-0 choose-file">Choose File</p>
            <select
              class="form-select"
              aria-label="Default select example"
              onChange={(e) => handleChange(e.target.value)}
            >
              <option selected>Select Folder</option>
              {folder?.map((item, index) => {
                return (
                  <>
                    <option value={item.id} key={index}>
                      {item.name}
                    </option>
                  </>
                );
              })}
            </select>
          </div>
          <div className="upload-sec text-center">
            <p className="m-0 mb-1">Upload File</p>
            <div className="browse d-flex align-items-center gap-1 justify-content-center">
              <AiOutlineCloudUpload className="fw-400 fs-2" />
              <label htmlFor="file-upload" className="mb-0">
                Browse...
              </label>
            </div>
            <div className="text-center file-upload-wrapper">
              <input
                id="file-upload"
                type="file"
                accept=".csv"
                onChange={onChange}
                disabled={optionId === "select folder" || ""}
              />
            </div>
            {fileName && <p className="mt-2">Selected File: {fileName}</p>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="handle">
            <Button onClick={handleClose} className="btnhandle closeBtn">
              Close
            </Button>
            <Button
              onClick={uploadFile}
              className="btnhandle uploadBtn"
              disabled={optionId === "select folder" || ""}
            >
              Upload {loader.upload ? <FaSpinner className="spin" /> : " "}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadCSV;
