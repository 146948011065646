import { combineReducers } from "redux";
import { AlertReducer } from "./AlertReducer";
import { AuthReducer } from "./AuthReducer";
// import { RebrandingReducer } from "./RebrandingReducer";
// import { HelpReducer } from "./HelpReducer";
// import { SocialReducer } from "./SocialReducer";
// import { CloneReducer } from "./CloneReducer";
// import { CloneSettingReducer } from "./CloneSettingReducer";
// import { ChatReducer } from "./ChatReducer";


const appReducer = combineReducers({
    alert: AlertReducer,
    auth: AuthReducer,
    // rebrand: RebrandingReducer,
    // help: HelpReducer,
    // social: SocialReducer,
    // clone: CloneReducer,
    // cloneSetting: CloneSettingReducer,
    // chat: ChatReducer
})

const rootReducers = (state, action) => {
    if (action.type === 'LOGOUT') {
        localStorage.clear();
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducers;