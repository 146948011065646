import { commonAxios } from "../Global/CommonAxios";
import { setAlert } from "./alertAction";

export const loginUser = (data, navigate, setLoader) => (dispatch) => {
    commonAxios("valid-login", data, dispatch)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch({ type: 'LOGIN_USER', payload: res.data });
                let token = res.data.token
                localStorage.setItem("token", token);
                navigate('/dashboard');
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })
}


export const logoutUser = (navigate) => (dispatch) => {
    let token = localStorage.getItem("token");
    commonAxios("logout-user", {}, dispatch, token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: 'LOGOUT' });
                dispatch(setAlert(res.msg, "success"));
                localStorage.clear()
                navigate('/login');
            }
            else {
                dispatch(setAlert(res.msg, "danger"));
            }
        })
        .catch((err) => {
            console.log(err)
        });
};

export const fetchData = (pageno, limit, datatable, setDatatable, setTotalRows, setLoader) => (dispatch) => {
    let token = localStorage.getItem("token");
    commonAxios("fetch-data", { pageno, limit }, dispatch, token,)
        .then((res) => {
            if (res.status) {
                setDatatable({ ...datatable, rows: res.data.campaign })

                setTotalRows(res.data.totalData)
            } else {
                setDatatable(prevDatatable => ({
                    ...prevDatatable,
                    rows: []
                }));
            }
            if (setLoader) {
                setLoader(false)
            }
        })
        .catch((err) => {
            console.log(err)

            if (setLoader) {
                setLoader(false)
            }
        });
};



export const fetchDataById = (data, datatable, setDatatable, setTotalRows, setLoader) => (dispatch) => {
    let token = localStorage.getItem("token");


    commonAxios("fetch-data-byid", data, dispatch, token)
        .then((res) => {
            if (res.status) {
                setDatatable({ ...datatable, rows: res.data.campaign })


                setTotalRows(res.data.totalData);
            } else {
                {
                    setDatatable(prevDatatable => ({
                        ...prevDatatable,
                        rows: []
                    }));
                }


            }
            if (setLoader) {
                setLoader(false)
            }
        })
        .catch((err) => {
            console.log("Error fetching data", err);
            setDatatable([]);
            if (setLoader) {
                setLoader(false)
            }
        })

};




export const onFetchFolder = (setFolder, setLoading) => (dispatch) => {
    let token = localStorage.getItem("token");
    commonAxios("fetch-folder", {}, dispatch, token)
        .then((res) => {
            if (res.status) {
                setFolder(res.data)
            } else {
                setFolder([])
            }
            setLoading(false)
        })
        .catch((err) => {
            console.log(err)
            setLoading(false)
        });
};

export const onCreateFolder = (data, fetchFolder) => (dispatch) => {
    let token = localStorage.getItem("token");
    commonAxios("create-folder", data, dispatch, token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, 'success'));
                fetchFolder()
            }
            else {
                dispatch(setAlert(res.msg, 'danger'));
            }
        })
        .catch((err) => {
            console.log(err)
        });
};

export const onUploadDocs = (ep, data, onCompelete, setPercent, handelFetchTableById, handelFetchTable, loader, setLoader) => (dispatch, getState) => {
    let token = localStorage.getItem("token");
    const config = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            setPercent(percent);
        },
        headers: {
            'Authorization': token
        },
    }
    setLoader({
        ...loader,
        upload: true
    })
    commonAxios(ep, data, dispatch, false, config)
        .then((res) => {
            if (res.status) {
                handelFetchTableById(res.data)
                handelFetchTable()
                dispatch(setAlert(res.msg, "success"))

                onCompelete(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }

            setPercent(0);
            setLoader({
                ...loader,
                upload: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                upload: false
            })
            console.log(err.msg)

            dispatch(setAlert("An error occurred during document upload.", "danger"));
        })
}

export const updateFolderName = (data, fetchFolder, handelFetchTableById) => (dispatch) => {
    let token = localStorage.getItem("token");


    commonAxios("update-folder-name", data, dispatch, token)
        .then((res) => {
            if (res.status === true) {
                fetchFolder();
                dispatch(setAlert(res.msg, 'success'));
                handelFetchTableById()
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
        })
        .catch((err) => {
            console.log(err);

        });
};


export const deleteFolder = (item, fetchFolder, handelFetchTable) => (dispatch) => {
    let token = localStorage.getItem("token");

    const data = {
        id: item
    };

    commonAxios("delete-folder", data, dispatch, token)
        .then((res) => {
            if (res.status) {

                if (fetchFolder) {
                    fetchFolder();
                    handelFetchTable()
                }
                dispatch(setAlert(res.msg, 'success'));


            } else {
                dispatch(setAlert(res.msg, 'danger'));

            }

        })
        .catch((err) => {
            console.log("Error deleting folder:", err);

        });
};


export const onDeleteTableData = (row, handelFetchTable) => (dispatch) => {
    let token = localStorage.getItem("token");

    const data = {
        id: row
    };

    commonAxios("delete-user-data", data, dispatch, token)
        .then((res) => {
            if (res.status) {
                if (handelFetchTable) {
                    handelFetchTable()
                }
                dispatch(setAlert(res.msg, 'success'));

            } else {

                dispatch(setAlert(res.msg, 'danger'));

            }
        })
        .catch((err) => {
            console.log("Error deleting folder:", err);
        });
};




export const onDeleteSelectData = (selectRow, handelFetchTableById, toggleCleared, setToggleCleared, setRefresh) => (dispatch) => {
    let token = localStorage.getItem("token");

    const data = {
        ids: selectRow
    };

    commonAxios("delete-all", data, dispatch, token)
        .then((res) => {
            if (res.status) {


                if (handelFetchTableById) {

                    handelFetchTableById()

                }

                setRefresh(pre => !pre)
                setToggleCleared(!toggleCleared);
                dispatch(setAlert(res.msg, 'success'));

            } else {
                dispatch(setAlert(res.msg, 'danger'));

            }
        })
        .catch((err) => {
            console.log("Error deleting folder:", err);
        });
};

