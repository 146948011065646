import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import './style.css'
import { useDispatch } from 'react-redux';
import { onCreateFolder, onFetchFolder } from '../action/authAction';

const CreateFolder = ({ setFolder, setLoader }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch()

    const [folderData, setFolderData] = useState({
        name: "",
        date: "",
    })


    const handleChange = (e) => {
        setFolderData({ ...folderData, [e.target.name]: e.target.value })
    }
    const fetchFolder = () => {
        dispatch(onFetchFolder(setFolder, setLoader));
    };
    const handleSave = (e) => {

        e.preventDefault()
        let data = {
            name: folderData.name,
            date: new Date(),
        }
        dispatch(onCreateFolder(data, fetchFolder))
        setFolderData('')
        setShow(false)
    }

    return (
        <>


            <Button className='btnCreate rounded-1 text-light px-3 py-2 px-1' variant="" onClick={handleShow}>
                Create Folder
            </Button>

            <Modal show={show} onHide={handleClose} centered >
                <Modal.Header closeButton>
                    <Modal.Title className='modal-heading'>Create Folder</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={handleSave}>
                        <div className='folder-name'>Folder Name:</div>
                        <input type='text' name='name' onChange={handleChange} value={folderData.name} maxLength={30} required className='rounded-2 editInput' />
                        <div className='handle d-flex justify-content-end'>
                            <Button type='submit' className='btnhandle createSaveBtn' >Save</Button>
                            <Button className="btnhandle createCloseBtn" onClick={handleClose}>Close</Button>
                        </div>
                    </form>
                    {/* <div>
                        Date
                    </div>
                    <input type='date' name='date' onChange={handleChange} value={folderData.value} required /> */}
                </Modal.Body>

            </Modal>
        </>
    );
}

export default CreateFolder;
