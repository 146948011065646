import React from 'react'
import { FaHeart } from 'react-icons/fa'
import './style.css'
const Footer = () => {
    return (
        <>
            <div className='footer'>
                <div>Copyright © 2020 <span>vineasx</span></div>
                <div>Developed with love <FaHeart /> by <a href='https://www.vega6.com/' target='_blank'>vega6</a></div>
            </div>
        </>
    )
}

export default Footer