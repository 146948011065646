import React from 'react';
import { Page, Text, View, Document, StyleSheet, Link, Image } from '@react-pdf/renderer';
import site_logo from '../../assets/images/site_logo.png'
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#E4E4E4',
        padding: 30,
    },
    section: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 20,
    },
    leftColumn: {
        width: '60%',
        paddingRight: '10px',
    },
    rightColumn: {
        width: '40%',
        paddingLeft: '10px',
    },
    heading: {
        fontSize: 13,
        fontWeight: 'bold',
        marginBottom: 10,
        textAlign: 'center',
        backgroundColor: '#1c84c6',
        color: 'white',
        padding: 10,
    },
    txt: {
        fontSize: 10,
        marginBottom: 5,
    },
    txtSol: {
        fontSize: 12,
        marginBottom: 5,
        color: '#1c84c6',
    },
    txtBank: {
        fontSize: 12,
        marginBottom: 5,
    },
    billTo: {
        backgroundColor: '#418ab3',
        color: '#FFF',
        padding: 8,
        marginBottom: 10,
        marginTop: 10,
        fontSize: 12,
        textAlign: 'center',
        width: '80%',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        marginTop: 20,
        fontSize: 10,

    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderTopWidth: 1,
        borderBottomColor: '#a6a6a6',
        borderTopColor: '#a6a6a6',
    },
    tableCell: {
        flex: 1,
        borderRightWidth: 1,
        borderLeftWidth: 1,
        borderRightColor: '#a6a6a6',
        borderLeftColor: '#a6a6a6',

        padding: 5,
        textAlign: 'center',
        fontSize: 10,
    },
    tableCellGrandData: {
        flex: 1,
        borderRightWidth: 1,
        borderLeftWidth: 1,
        borderLeftColor: '#a6a6a6',
        borderRightColor: '#a6a6a6',
        padding: 5,
        textAlign: 'start',
        fontSize: 10,
    },
    tableHeader: {
        backgroundColor: '#418ab3',
        color: '#FFFFFF',
        fontSize: 10,
        fontWeight: 'bold',
    },
    tableData: {
        backgroundColor: '#d8e9f1',
    },
    total: {
        textAlign: 'center',
        padding: 5,
        backgroundColor: '#d8e9f1',
    },
    paymentTerms: {
        color: '#1c84c6',
        textDecoration: "underline"
    },
    bankDetails: {
        paddingTop: 20,

    },
    footer: {
        textAlign: 'center',
        paddingTop: 30,
        borderBottomWidth: 1,
        paddingBottom: 10,
    },
    link: {
        color: '#418ab3',
    },
    textBlue: {
        color: '#418ab3',
    },
    textOrange: {
        color: '#f59e00',
        fontSize: 12,
    },
    textCenter: {
        textAlign: 'center',
    },
    txtdata: {
        textAlign: 'center',
        fontSize: 10,
    },
    headingData: {
        fontSize: 12,
        fontWeight: 'bold',
        marginBottom: 10,
        textAlign: 'center',
        backgroundColor: '#418ab3',
        color: '#FFF',
        padding: 10,
        marginBottom: 10,
        marginTop: 10,

    },
    companyTrn: {
        fontSize: 10,
        border: '1px solid #a6a6a6',
        width: '80%',
        padding: 10,
        marginTop: 10,
    },

    tableDataRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottomWidth: 1,
        borderBottomColor: '#a6a6a6',
        // paddingVertical: 5,
    },
    container: {
        flexDirection: 'row',

    },
    leftColumnMain: {
        flex: 5.30,
        borderLeftWidth: 1,
        borderBottomWidth: 1,
        borderBottomColor: '#a6a6a6',
        borderLeftColor: '#a6a6a6',
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    rightColumnMain: {
        flex: 4,
        borderBottomWidth: 1,
        borderBottomColor: '#a6a6a6',
    },
    tableCellSubtotal: {
        borderRightWidth: 1,
        borderRightColor: '#a6a6a6',
    },
    tableCellSub: {
        borderBottomWidth: 1,

        borderBottomColor: '#a6a6a6',

    },


    taxRate: {
        flex: 1,
        borderRightWidth: 1,
        borderLeftWidth: 1,
        borderRightColor: '#a6a6a6',
        borderLeftColor: '#a6a6a6',

        padding: 5,
        textAlign: 'start',
        fontSize: 10,
    },

});

const PdfData = ({ row }) => {
    const priceAED = (row?.price * 3.67).toFixed(2);
    const tax = (row?.price * 3.67 * 0.05).toFixed(2);
    const subtotal = (parseFloat(priceAED) + parseFloat(tax)).toFixed(2);
    const zeroTax = row.location === 'US' ? tax : '0.00';
    const subtotalZeroTax = (parseFloat(priceAED) + parseFloat(zeroTax)).toFixed(2);

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.leftColumn}>
                        <Image src={site_logo} style={{ width: 150, height: 30, marginBottom: 15, backgroundColor: '#1c84c6', }} />
                        <Text style={styles.txtSol}>Vineasx Solutions LLC</Text>
                        <Text style={styles.txtSol}>I-Rise Tower, Al Barsha Heights</Text>
                        <Text style={styles.txtSol}>Dubai, UAE</Text>
                        <Text style={styles.txtSol}>TRN: 100423356300003</Text>
                        <Text style={styles.txtSol}>Email Address: support@vineasx.com</Text>
                        <Text style={styles.txtSol}>Point of Contact: Alyssa David</Text>
                        <Text style={styles.billTo}>BILL TO</Text>
                        <Text style={styles.txt}>Customer Name: {row.name}</Text>
                        <Text style={styles.txt}>Email Address: {row.email}</Text>
                        <Text style={styles.txt}>Location: {row.location}</Text>
                        <Text style={styles.companyTrn}>Company TRN:</Text>
                    </View>
                    <View style={styles.rightColumn}>
                        <Text style={styles.txtdata}> TAX INVOICE</Text>
                        <Text style={styles.headingData}>DATE</Text>
                        <Text style={styles.txtdata}>{row.date}</Text>
                        <Text style={styles.headingData}>INVOICE NO.</Text>
                        <Text style={styles.txtdata}>VSX000{Math.floor(Math.random() * 1000000000)}</Text>
                        <Text style={styles.headingData}>Transaction ID</Text>
                        <Text style={styles.txtdata}>{row.transaction}</Text>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text style={[styles.tableCell, styles.tableHeader]}>ITEM NO.</Text>
                        <Text style={[styles.tableCell, styles.tableHeader]}>DESCRIPTION</Text>
                        <Text style={[styles.tableCell, styles.tableHeader]}>QTY</Text>
                        <Text style={[styles.tableCell, styles.tableHeader]}>UNIT PRICE (USD)</Text>
                        <Text style={[styles.tableCell, styles.tableHeader]}>RATE USD=AED</Text>
                        <Text style={[styles.tableCell, styles.tableHeader]}>UNIT PRICE (AED)</Text>
                        <Text style={[styles.tableCell, styles.tableHeader]}>TOTAL</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCell}>1</Text>
                        <Text style={styles.tableCell}>{row?.productName}</Text>
                        <Text style={styles.tableCell}>1</Text>
                        <Text style={styles.tableCell}>{row?.price}</Text>
                        <Text style={styles.tableCell}>3.67</Text>
                        <Text style={styles.tableCell}>{priceAED}</Text>
                        <Text style={styles.tableCell}>{priceAED}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCell} />
                        <Text style={styles.tableCell} />
                        <Text style={styles.tableCell} />
                        <Text style={styles.tableCell} />
                        <Text style={styles.tableCell} />
                        <Text style={[styles.tableCell, styles.tableData]}>-</Text>
                        <Text style={[styles.tableCell, styles.tableData]}>-</Text>
                    </View>


                    <View style={styles.container}>
                        <View style={styles.leftColumnMain}>
                            <Text style={styles.paymentTerms}>
                                Payment Terms: Immediately on receipt of invoice.
                            </Text>
                        </View>
                        <View style={styles.rightColumnMain}>
                            <View style={styles.tableDataRow}>
                                <Text style={[styles.tableCellGrandData, styles.tableData, { flex: 2.20 }]}>SUBTOTAL</Text>
                                <Text style={[styles.tableCell, styles.tableData, styles.total]}>
                                    {priceAED}
                                </Text>
                            </View>
                            <View style={styles.tableDataRow}>
                                <Text style={[styles.taxRate, styles.tableData]}>
                                    TAX RATE
                                </Text>
                                <Text style={styles.tableCell}>
                                    {row.location === 'US' ? '5%' : '0%'}
                                </Text>
                                <Text style={[styles.tableCell, styles.tableData, styles.total]}>
                                    {row.location === 'US' ? tax : zeroTax}
                                </Text>
                            </View>
                            <View style={styles.tableDataRow}>
                                <Text style={[styles.tableCellGrandData, styles.tableData, { flex: 2.20 }]}>
                                    DISCOUNT
                                </Text>
                                <Text style={[styles.tableCell, styles.tableData]}>
                                    -
                                </Text>
                            </View>

                            <View style={styles.tableDataRow}>
                                <Text style={[styles.tableCellGrandData, styles.tableData, { flex: 2.20 }]}>
                                    GRAND TOTAL
                                </Text>

                                <Text style={[styles.tableCell, styles.tableData, styles.total]}>
                                    {row.location === 'US' ? subtotal : subtotalZeroTax}
                                </Text>
                            </View>
                        </View>
                    </View>


                </View>


                <View style={styles.bankDetails}>
                    <Text style={styles.txtBank}>Bank Details:</Text>
                    <Text style={styles.txt}>Account Name: VINEASX SOLUTIONS LLC</Text>
                    <Text style={styles.txt}>Bank Name: Mashreq Bank</Text>
                    <Text style={styles.txt}>SWIFT Code: BOMLAEAD</Text>
                    <Text style={styles.txt}>IBAN Number: AE850330000019100198106</Text>
                    <Text style={styles.txt}>Routing Number: 203320101</Text>
                    <Text style={styles.txt}>PayPal Email: abhi@vineasx.com</Text>
                </View>



                <View style={styles.footer}>
                    <Text style={styles.txt}>
                        <Text style={{ fontStyle: 'italic' }}>
                            For billing inquiries, please email:{' '}
                            <Link style={styles.textBlue} src="mailto:support@vineasx.com">
                                support@vineasx.com
                            </Link>
                        </Text>
                    </Text>
                    <Text style={styles.txt}>
                        Visit our website: {' '}
                        <Link style={styles.link} src="https://www.vineasx.com/">
                            https://www.vineasx.com/
                        </Link>
                    </Text>
                    <Text style={[styles.txt, styles.textOrange]}>
                        <Text style={{ fontWeight: 'bold' }}>
                            Thank you for your business!
                        </Text>
                    </Text>
                </View>
            </Page>
        </Document >
    );
};

export default PdfData;
